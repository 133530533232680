<template id="">
  <div class="wrapper">
  <v-card class="paper brown lighten-5" v-if="task_index !== 2">
    <div class="header brown lighten-2">
      <h2>Einkaufsliste</h2>
    </div>
    <div class="body">
    <div
      class="item"
      v-for="(item, index) in list"
      :key="`item${index}`"
      >
      <v-icon
        @click="item.done = !item.done"
        class="mr-1"
        >
        {{item.done?'mdi-check-circle-outline':'mdi-circle-outline'}}
      </v-icon>
      {{item.text}}
      <v-btn
        icon color="red"
        style="position: absolute; right: 0px;"
        @click="list.splice(index, 1)"
        >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <v-chip class="input">
      <input class="input-field" v-model="input">
      <v-btn
        icon
        style="position: absolute; right: 6px"
        @click="addItem()"
        >
        <v-icon large>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-chip>
    </div>
  </v-card>
  <video
    v-if="task_index === 2 && video_playing"
    height="720"
    width="1200"
    :autoplay="true"
    @ended="task_index++"
    src="@/assets/video-tut.mp4"
    controls
  />
  <div class="video-box" v-if="task_index !== 2">
    <!-- ende tut start aufgaben -->
    <video
      v-if="task_index === 3 && video_playing"
      height="300"
      width="400"
      :autoplay="true"
      @ended="video_playing = false, task_index++"
      src="@/assets/Tut3.mp4"
    />
    <video
      v-if="task_index === 4 && video_playing"
      height="300"
      width="400"
      :autoplay="true"
      @ended="video_playing = false"
      src="@/assets/Tut0.mp4"
    />
    <video
      v-else-if="task_index === 5 && video_playing"
      height="300"
      width="400"
      :autoplay="true"
      @ended="video_playing = false"
      src="@/assets/Tut1.mp4"
    />
    <video
      v-else-if="task_index === 6 && video_playing"
      height="300"
      width="400"
      :autoplay="true"
      @ended="video_playing = false"
      src="@/assets/Tut2.mp4"
    />
    <video
      v-else-if="task_index === 7 && video_playing"
      height="300"
      width="400"
      :autoplay="true"
      @ended="video_playing = false"
      src="@/assets/Tut4.mp4"
    />
    <div v-else-if="!video_playing" class="task">
      <h3 v-if="task_index < 3">Übung {{task_index+1}}/3:</h3>
      <h3 v-else-if="task_index < 7">Erfolgskontrolle Aufgabe {{task_index-3}}/3:</h3>
      <div>{{task_texts[task_index]}}</div>
    </div>
    <v-btn v-if="!video_playing" @click="video_playing = true" style="position: absolute; bottom: 0px">
      Hilfe
    </v-btn>
  </div>
</div>
</template>
<script>
  export default {
    name: 'InterActiveTut',
    data: () => ({
      tutorial: '',
      input: '',
      list: [{text: 'Äpfel', done: false}],
      task_index: 2,
      // tasks: [false, false, false],
      // videos: [false, false, false],
      video_playing: true,
      task_texts: [
        'Füge zwei beliebige Artikel der Einkaufsliste hinzu, sodass sich drei Artikel in der Einkaufsliste befinden.',
        'Lösche einen beliebigen Artikel aus der Einkaufsliste, sodass sich nurnoch zwei Artikel in der Einkaufsliste befinden.',
        'Markiere alle Artikel in der Einkaufsliste als erledigt.',
        '',
        'Füge zwei beliebige Artikel der Einkaufsliste hinzu, sodass sich drei Artikel in der Einkaufsliste befinden.',
        'Lösche einen beliebigen Artikel aus der Einkaufsliste, sodass sich nurnoch zwei Artikel in der Einkaufsliste befinden.',
        'Markiere alle Artikel in der Einkaufsliste als erledigt.',
        'Seh   gut, du hast alle Aufgaben gemeistert!'
      ]
    }),
    watch: {
      list: {
        deep: true,
        handler() {
          //Testat
          if (this.task_index === 4 && this.list.length === 3) {
            this.task_index++;
          } else if (this.task_index === 5 && this.list.length === 2) {
            this.task_index++;
          } else if (this.task_index === 6) {
            if (this.list.length === 0) {
              this.list.push({text: 'Äpfel', done: false})
            } else {
              let boolean = true;
              this.list.forEach(item => {
                if (!item.done) {
                  boolean = false;
                }
              })
              if (boolean) {
                this.task_index++;
                this.video_playing = true;
              }
            }
          }
        }
      }
    },
    methods: {
      addItem() {
        if (this.input !== '') {
          let item = {
            text: JSON.parse(JSON.stringify(this.input)),
            done: false,
          }
          this.list.push(item);
          this.input = '';
        }
      }
    }
  }
</script>
<style>
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .paper {
    position: absolute;
    width: 400px;
    height: 100%;
    margin-top: 46px;
  }
  .header {
    display: flex;
    justify-content: center;
  }
  .body {
    position: relative;
    padding: 6px;
    height: calc(100% - 36px);
    width: 100%;
  }
  .input {
    position: absolute !important;
    width: calc(100% - 12px) !important;
    bottom: 6px;
  }
  .input-field {
    position: relative;
    outline: none;
    width: 330px !important;
    font-size: 20px;
  }
  .item {
    display: flex;
    align-items: center;
    height: 36px;
    border-bottom: 1px solid #D7CCC8;
  }
  .video-box {
    position: absolute;
    top: 10px;
    right: 0px;
    /* background-color: blue; */
    height: 300px;
    width: 400px;
    border: solid 1px black

  }
</style>
