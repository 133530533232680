<template>
  <v-app>
    <v-app-bar
      app
      color="blue"
      dark
    >
      <div class="d-flex align-center justify-center">
        <h1>Video Tutorial Test</h1>
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
      </div>
      <v-spacer></v-spacer>

      <h1 v-if="tutorial === 'InterActiveTut'">
        Interaktives Tutorial
      </h1>
      <h1 v-if="tutorial === 'VideoTut'">
        Video Tutorial
      </h1>

      <v-spacer></v-spacer>
      <v-btn v-if="tutorial !== ''" @click="tutorial = ''">Tutorial beenden</v-btn>
      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main >
      <div style="display: flex; justify-content: center; align-items: center; height: calc(100% - 64px)">
      <InterActiveTut v-if="tutorial === 'InterActiveTut'"/>
      <VideoTut v-else-if="tutorial === 'VideoTut'"/>
      <div v-else style="display: flex;">
        <v-btn
          class="mr-6"
          @click="tutorial = 'InterActiveTut'"
          >
          Starte Interaktives Tutorial
        </v-btn>

        <v-btn
          class="ml-6"
          @click="tutorial = 'VideoTut'"
          >
          Starte Video Tutorial
        </v-btn>
      </div>
      <!-- <HelloWorld/> -->
    </div>
    </v-main>
  </v-app>
</template>

<script>
import InterActiveTut from './components/InterActiveTut';
import VideoTut from './components/VideoTut';

export default {
  name: 'App',

  components: {
    InterActiveTut,
    VideoTut
  },

  data: () => ({
    tutorial: '',
  }),
};
</script>
